import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { getPostCover } from '@wix/communities-blog-client-common';

import { connect } from '../../../common/components/runtime-context';
import Banner from '../../components/banner';
import withTranslate from '../../../common/hoc/with-translate';
import { getLastFeaturedPost, getLastFeaturedPostCategory } from '../../selectors/last-featured-post-selectors';
import styles from './featured-post-banner.scss';
import withMediaHosts from '../../../common/hoc/with-media-hosts';

class FeaturedPostBanner extends React.Component {
  render() {
    const { lastFeaturedPost, lastFeaturedPostCategory, imageHost, videoHost, t } = this.props;
    if (!lastFeaturedPost) {
      return null;
    }
    const category = lastFeaturedPostCategory && lastFeaturedPostCategory.label;
    const link = { to: `/${lastFeaturedPost.slug}`, text: t('banner.read-more-label') };
    const { shouldRender, imageMetadata } = getPostCover(lastFeaturedPost, imageHost, videoHost);
    const image = shouldRender ? imageMetadata : null;
    return (
      <div className={styles.spacer}>
        <Banner title={lastFeaturedPost.title} category={category} link={link} image={image} />
      </div>
    );
  }
}

FeaturedPostBanner.propTypes = {
  lastFeaturedPost: PropTypes.object,
  lastFeaturedPostCategory: PropTypes.object,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state) => ({
  lastFeaturedPost: getLastFeaturedPost(state),
  lastFeaturedPostCategory: getLastFeaturedPostCategory(state),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate, withMediaHosts)(FeaturedPostBanner);
