import { flowRight, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../components/runtime-context';
import hoistNonReactStatics from 'hoist-non-react-statics';
import preventClickInDemoMode from './prevent-click-in-demo-mode';
import createHocName from '../services/create-hoc-name';
import withAuth from './with-auth';

const NAME = 'EnsureAuth';

export default function ensureAuth(WrappedComponent) {
  const ChildComponent = preventClickInDemoMode(WrappedComponent);

  class EnsureAuth extends Component {
    static displayName = createHocName(NAME, WrappedComponent);

    handleClick = (event) => {
      const { isAuthenticated, onClick, requestLogin } = this.props;
      if (isAuthenticated) {
        onClick && onClick(event);
      } else {
        event.preventDefault();
        event.stopPropagation();

        requestLogin().then(() => onClick && onClick(event));
      }
    };

    render() {
      const otherProps = omit(this.props, ['isAuthenticated', 'onClick', 'requestLogin']);
      return <ChildComponent {...otherProps} onClick={this.handleClick} />;
    }
  }

  EnsureAuth.propTypes = {
    requestLogin: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    onClick: PropTypes.func,
  };

  hoistNonReactStatics(EnsureAuth, ChildComponent);

  const mapRuntimeProps = (state, ownProps, actions) => ({
    requestLogin: actions.requestLoginPromisified,
  });

  return flowRight(connect(mapRuntimeProps), withAuth)(EnsureAuth);
}
