import React from 'react';
import PropTypes from 'prop-types';
import { ComponentsContext } from './components-context';

const Fallback = () => null;

const ComponentsProvider = ({ children, ...rest }) => (
  <ComponentsContext.Provider value={{ ...rest }}>{React.Children.only(children)}</ComponentsContext.Provider>
);

ComponentsProvider.defaultProps = {
  PostListProGallery: Fallback,
  PostListSimple: Fallback,
  PostListMasonry: Fallback,
  PostListMobile: Fallback,
  PostListItem: Fallback,
  Post: Fallback,
  SideBySideMobile: Fallback,
  TextOnImageMobile: Fallback,
};

ComponentsProvider.propTypes = {
  PostListProGallery: PropTypes.elementType.isRequired,
  PostListSimple: PropTypes.elementType.isRequired,
  PostListMasonry: PropTypes.elementType.isRequired,
  PostListMobile: PropTypes.elementType.isRequired,
  PostListItem: PropTypes.elementType.isRequired,
  Post: PropTypes.elementType.isRequired,
  SideBySideMobile: PropTypes.elementType.isRequired,
  TextOnImageMobile: PropTypes.elementType.isRequired,
};

export default ComponentsProvider;
