import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './icon-button.scss';

const IconButton = ({ component: Component, className, icon: Icon, iconClassName, label, children, ...props }) => {
  const componentProps = omit(props, ['tReady', 'lng']);

  return (
    <Component className={classNames(styles.button, className)} {...componentProps}>
      <span className={styles.wrapper}>
        <Icon className={iconClassName} />
        <span className={styles.label}>{label}</span>
      </span>
      {children}
    </Component>
  );
};

IconButton.propTypes = {
  component: PropTypes.any,
  className: PropTypes.string,
  icon: PropTypes.func.isRequired,
  iconClassName: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
};

IconButton.defaultProps = {
  component: 'button',
};

export default IconButton;
