import PropTypes from 'prop-types';
import React from 'react';
import EmptyStates from '../../../common/components/empty-states';
import withTranslate from '../../../common/hoc/with-translate';

const NoPosts = ({ t, ...props }) => (
  <EmptyStates title={t('no-posts.posts-coming-soon')} content={t('no-posts.explore-other-categories')} {...props} />
);

NoPosts.propTypes = {
  t: PropTypes.func,
};

export default withTranslate(NoPosts);
