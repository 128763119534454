import React from 'react';
import { I18nextProvider } from 'react-i18next';
import 'reset-css';
import { ImgProvider, RENDER_IMAGE, RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY } from '@wix/communities-image-lib';
import { initI18n, isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_OOI_IN_EDITOR } from '@wix/communities-blog-experiments';

import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import PermissionsProvider from '../../../common/components/permissions-provider';
import Router from '../router';
import { getLanguage, isSeo, getIsMobile, isEditor } from '../../../common/store/basic-params/basic-params-selectors';
import { isWebpSupported } from '../../../common/services/is-webp-supported';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import '../../../rich-content/components/rich-content-editor/rich-content-editor.global.scss';
import pinterest from '../../../common/services/pinterest';
import {
  getPinterestEnabled,
  getIsCreatedWithResponsiveEditor,
} from '../../../common/selectors/app-settings-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import WindowResizeListener from '../../../common/components/window-resize-listener';
import BlogPushNotifications from '../../../common/components/blog-push-notifications';
import ComponentsProvider from '../../../common/components/components-provider';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import PostListSimple from '../../components/post-list/post-list-simple';
import PostListMobile from '../../components/post-list/post-list-mobile';
import PostListMasonry from '../../components/post-list/post-list-masonry';
import PostListItem from '../../components/post-list-item';
import SideBySideMobile from '../../components/post-list-item/mobile/side-by-side';
import TextOnImageMobile from '../../components/post-list-item/mobile/text-on-image';
import Post from '../../../common/components/post';
import { buildPlaceholderSrc } from '../../../common/services/build-placeholder-src';
import './app-root.scss';

class AppRoot extends React.Component {
  constructor(props) {
    super(props);
    const { language, isMobile, isPinterestEnabled } = this.props;
    pinterest.init({ language, isMobile, isPinterestEnabled });
  }

  componentWillUnmount() {
    pinterest.removeEventListener();
  }

  getI18nConfig = (language, translations) => {
    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { addResizeListener, language, translations, isSeo, isMobile } = this.props;
    const i18n = this.getI18nConfig(language, translations);

    return (
      <ResponsiveListener>
        <PermissionsProvider>
          <ComponentsProvider
            PostListProGallery={PostListProGallery}
            PostListSimple={PostListSimple}
            PostListMasonry={PostListMasonry}
            PostListMobile={PostListMobile}
            PostListItem={PostListItem}
            Post={Post}
            SideBySideMobile={SideBySideMobile}
            TextOnImageMobile={TextOnImageMobile}
          >
            <I18nextProvider i18n={i18n}>
              <ImgProvider
                renderMode={isSeo ? RENDER_IMAGE : RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY}
                buildPlaceholderSrc={isMobile ? buildPlaceholderSrc : undefined}
                {...(isWebpSupported() ? { format: 'webp' } : {})}
              >
                <>
                  <Router />
                  {addResizeListener && <WindowResizeListener />}
                  <BlogPushNotifications />
                </>
              </ImgProvider>
            </I18nextProvider>
          </ComponentsProvider>
        </PermissionsProvider>
      </ResponsiveListener>
    );
  }
}

const mapRuntimeToProps = (state) => {
  const addResizeListener =
    isEditor(state) &&
    !isExperimentEnabled(state, EXPERIMENT_OOI_IN_EDITOR) &&
    !getIsCreatedWithResponsiveEditor(state);
  return {
    language: getLanguage(state),
    translations: getTranslations(state),
    isSeo: isSeo(state),
    isMobile: getIsMobile(state),
    isPinterestEnabled: getPinterestEnabled(state),
    addResizeListener,
  };
};

export default withReduxStore(connect(mapRuntimeToProps)(AppRoot));
