import { find, invoke, escapeRegExp } from 'lodash';

export const buildUrl = (url, videoHost) => {
  const OPTIONAL_QUERY_PARAMS_PATTERN = /(?:&?[^=&]*=[^=&]*)*/;
  const YOUTUBE_VIDEO_ID_PATTERN = /&?v=([a-z\-_\d]+)/;

  const YOUTUBE_PATTERNS = [
    () => /https?:\/\/www\.youtube\.com\/embed\/([a-z\-_\d]+)/gi,
    () =>
      new RegExp(
        /https?:\/\/www\.youtube\.com\/watch\?/.source +
          OPTIONAL_QUERY_PARAMS_PATTERN.source +
          YOUTUBE_VIDEO_ID_PATTERN.source,
        'i',
      ),
    () =>
      new RegExp(
        /https?:\/\/m\.youtube\.com\/watch\?/.source +
          OPTIONAL_QUERY_PARAMS_PATTERN.source +
          YOUTUBE_VIDEO_ID_PATTERN.source,
        'i',
      ),
    () => /https?:\/\/youtu\.be\/([a-z\-_\d]+)/gi,
  ];
  const VIMEO_PATTERNS = [() => /https?:\/\/vimeo\.com\/(\d+)/gi, () => /https:\/\/player\.vimeo\.com\/video\/(\d+)/gi];
  const MEDIA_MANAGER_PATTERNS = [() => new RegExp(`${escapeRegExp(videoHost)}/video/.+/.+/mp4/file.mp4$`, 'i')];

  const _isSupported = (patterns, url) => {
    let result = false;
    patterns
      .map((fn) => fn())
      .forEach((pattern) => {
        result = result || Boolean(pattern.exec(url));
      });
    return result;
  };

  const extractVideoId = (patterns, url) => {
    let result;
    patterns
      .map((fn) => fn())
      .forEach((pattern) => {
        const matches = pattern.exec(url);
        if (matches) {
          result = matches[1];
        }
      });
    return result;
  };

  const PROVIDERS = [
    {
      isSupported: (url) => _isSupported(YOUTUBE_PATTERNS, url),
      buildEmbedUrl: (url) => {
        const videoId = extractVideoId(YOUTUBE_PATTERNS, url);
        return `https://www.youtube.com/embed/${videoId}?enablejsapi=1`;
      },
      buildUrl: (url) => {
        const videoId = extractVideoId(YOUTUBE_PATTERNS, url);
        return `https://youtu.be/${videoId}`;
      },
    },
    {
      isSupported: (url) => _isSupported(VIMEO_PATTERNS, url),
      buildEmbedUrl: (url) => {
        const videoId = extractVideoId(VIMEO_PATTERNS, url);
        return `https://player.vimeo.com/video/${videoId}?api=1`;
      },
      buildUrl: (url) => {
        const videoId = extractVideoId(VIMEO_PATTERNS, url);
        return `https://vimeo.com/${videoId}`;
      },
    },
    {
      isSupported: (url) => _isSupported(MEDIA_MANAGER_PATTERNS, url),
      buildEmbedUrl: (url) => url,
      buildUrl: (url) => url,
    },
  ];

  const getProvider = (url) => {
    return find(PROVIDERS, (provider) => provider.isSupported(url));
  };

  return invoke(getProvider(url), 'buildUrl', url);
};
