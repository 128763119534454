import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP } from '@wix/communities-blog-experiments';
import HeaderButtons from '../header-buttons';
import HeaderTitle from '../header-title';
import withFontClassName from '../../hoc/with-font-class-name';
import withBlogMenuSettings from '../../hoc/with-blog-menu-settings';
import withExperiment from '../../hoc/with-experiment';
import styles from './header.scss';

export class Header extends Component {
  render() {
    const {
      left,
      right,
      menu,
      contentFontClassName,
      hideNavBar,
      showBlogMenu,
      showCategoryLabels,
      isMobileMenuColorMatchDesktopExperimentEnabled,
    } = this.props;

    const containerClassName = isMobileMenuColorMatchDesktopExperimentEnabled
      ? classNames(
          styles.headerContainer,
          'blog-navigation-container-color',
          'blog-header-background-color',
          'blog-navigation-container-font',
        )
      : classNames(styles.headerContainer, 'blog-button-primary-text-color', contentFontClassName);

    const showMenu = menu && showBlogMenu && showCategoryLabels;
    const wrapperClassName = isMobileMenuColorMatchDesktopExperimentEnabled
      ? classNames(styles.header, 'blog-navigation-divider-border-color')
      : classNames(styles.header, 'blog-button-background-color');

    const wrapperStyle = isMobileMenuColorMatchDesktopExperimentEnabled ? { borderBottom: `1px solid` } : undefined;
    const menuClassName = isMobileMenuColorMatchDesktopExperimentEnabled
      ? 'blog-navigation-divider-border-color'
      : 'blog-button-background-color';

    return (
      <div className={containerClassName}>
        {!hideNavBar && showBlogMenu && (
          <div className={wrapperClassName} style={wrapperStyle}>
            {left}
            {right}
          </div>
        )}
        {showMenu && (
          <div className={menuClassName} style={wrapperStyle}>
            {menu}
          </div>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  contentFontClassName: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  menu: PropTypes.node,
  hideNavBar: PropTypes.bool,
  showBlogMenu: PropTypes.bool,
  showCategoryLabels: PropTypes.bool,
  isMobileMenuColorMatchDesktopExperimentEnabled: PropTypes.bool,
};

Header.defaultProps = {
  right: <HeaderButtons />,
  left: (
    <div className={styles.left}>
      <span className={styles.title}>
        <HeaderTitle />
      </span>
    </div>
  ),
};

export default flowRight(
  withFontClassName,
  withBlogMenuSettings,
  withExperiment({
    isMobileMenuColorMatchDesktopExperimentEnabled: EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP,
  }),
)(Header);
