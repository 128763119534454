import { get, debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { encodeQuery, decodeQuery } from '../../services/query-encoding';
import SearchInput from '../../../common/components/search-input';
import HeaderWidget from '../../../common/components/header-widget';

const DEBOUNCE_WAIT = 500;

export const SearchHeader = ({
  component: Component,
  left,
  query,
  inputClassName,
  iconClassName,
  borderClassName,
  showWidget,
  navigateToSearch,
  ...props
}) => {
  const handleQueryChange = debounce((query) => navigateToSearch(query), DEBOUNCE_WAIT);

  const searchInput = (
    <SearchInput
      isExpanded
      isFullWidth={!showWidget}
      value={query}
      onChange={handleQueryChange}
      inputClassName={inputClassName}
      iconClassName={iconClassName}
      borderClassName={borderClassName}
    />
  );

  return (
    <Component left={left} right={showWidget ? <HeaderWidget searchInput={searchInput} /> : searchInput} {...props} />
  );
};

SearchHeader.propTypes = {
  component: PropTypes.any,
  left: PropTypes.node,
  query: PropTypes.string.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  borderClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  showWidget: PropTypes.bool,
};

SearchHeader.defaultProps = {
  showWidget: true,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  query: decodeURIComponent(decodeQuery(get(ownProps, 'params.query', ''))),
  navigateToSearch: (query) => actions.navigateWithinBlog(query ? `/search/${encodeQuery(query)}` : '/search'),
});

export default connect(mapRuntimeToProps)(SearchHeader);
