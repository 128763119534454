import { flowRight } from 'lodash';
import { connect } from '../components/runtime-context';

import {
  getIsBlogMenuEnabled,
  getIsBlogMenuCategoryLabelsEnabled,
  getIsBlogMenuSearchEnabled,
  getIsBlogMenuLoginButtonEnabled,
} from '../selectors/app-settings-selectors';
import withHocName from './with-hoc-name';

const mapRuntimeToProps = (state) => ({
  showBlogMenu: getIsBlogMenuEnabled(state),
  showCategoryLabels: getIsBlogMenuCategoryLabelsEnabled(state),
  showSearch: getIsBlogMenuSearchEnabled(state),
  showLoginButton: getIsBlogMenuLoginButtonEnabled(state),
});

export default flowRight(connect(mapRuntimeToProps), withHocName('WithBlogMenuSettings'));
