import { get, flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';

import { SECTION_CATEGORY, isLayoutProGallery, createPageUrl } from '@wix/communities-blog-client-common';
import { getIsArchiveLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsArchiveLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getSortedPostsByArchiveDate, getPostsByArchiveDateAndPage } from '../../../common/selectors/post-selectors';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import withAuth from '../../../common/hoc/with-auth';
import withLayoutProps from '../../../common/hoc/with-layout-props';
import withPaginationSettings from '../../hoc/with-pagination-settings';
import withPermissions from '../../../common/hoc/with-permissions';
import withTranslate from '../../../common/hoc/with-translate';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import Page from '../../../common/components/page';
import Pagination from '../../../common/containers/pagination';
import PostList from '../../components/post-list';
import withComponents from '../../../common/components/components-provider/with-components';
import { EmptyLayout } from '../../../common/components/layout';
import { handleNavigation } from '../../../common/services/navigation';
import { getIsMobile, isSite } from '../../../common/store/basic-params/basic-params-selectors';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';
import { getArchiveUrl } from '../../../common/services/get-archive-url';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { getCanSeeMoreButton } from '../../../common/selectors/app-settings-selectors';

const Layout = EmptyLayout;

export class ArchivePage extends Component {
  state = {
    isAnimated: !this.props.allPosts.length,
  };

  componentWillUnmount() {
    this.props.setIsLoaded('archive', null, true);
  }

  componentDidUpdate({ isAuthenticated, isBlocked, pageSize, excludeContent }) {
    const { year, month } = this.props;
    if (
      this.props.isAuthenticated !== isAuthenticated ||
      this.props.isBlocked !== isBlocked ||
      this.props.pageSize !== pageSize ||
      (excludeContent && !this.props.excludeContent)
    ) {
      this.fetchPosts(year, month);
    }
  }

  fetchPosts(year, month, page = this.props.page) {
    if (this.props.isBlocked) {
      return;
    }

    this.props.fetchArchive(year, month, page);
  }

  loadMore = (page) => this.fetchPosts(this.props.year, this.props.month, page);

  renderPosts() {
    const {
      currentPagePosts,
      allPosts,
      entityCount,
      showCreatePostAction,
      page,
      layoutType,
      showPagination,
      isArchiveLoading,
      isArchiveLoaded,
      PostListProGallery,
      canSeeMoreButton,
    } = this.props;

    if (isLayoutProGallery(layoutType)) {
      return (
        <PostListProGallery
          pageStart={page + 1}
          entityCount={entityCount}
          loadMore={showPagination ? undefined : this.loadMore}
          allPosts={showPagination ? currentPagePosts : allPosts}
          currentPagePosts={currentPagePosts}
          isLoading={isArchiveLoading}
          showCreatePostAction={showCreatePostAction}
          canSeeMoreButton={canSeeMoreButton}
          section={SECTION_CATEGORY}
        />
      );
    }

    return (
      <PostList
        pageStart={page + 1}
        layoutType={layoutType}
        entityCount={entityCount}
        loadMore={showPagination ? undefined : this.loadMore}
        allPosts={showPagination ? currentPagePosts : allPosts}
        currentPagePosts={currentPagePosts}
        isLoading={isArchiveLoading}
        isLoaded={isArchiveLoaded}
        showCreatePostAction={showCreatePostAction}
      />
    );
  }

  renderPagination() {
    const { entityCount, pageSize, page, archiveUrl, isSite, showPagination } = this.props;

    return (
      <div data-hook="archive-pagination-container" style={{ display: showPagination ? 'block' : 'none' }}>
        <Pagination
          page={page}
          pageSize={pageSize}
          entityCount={entityCount}
          createPageUrl={(page) => createPageUrl(page, archiveUrl)}
          handleNavigation={handleNavigation('archive', isSite)}
          showPagination={showPagination}
        />
      </div>
    );
  }

  render() {
    const { allPosts, isArchiveLoading, isArchiveLoaded } = this.props;

    const isLoading = isArchiveLoading && !isArchiveLoaded;
    return (
      <Page noSpacing>
        <Layout>
          <AnimatedLoader isLoading={isLoading && !allPosts.length} isAnimated={this.state.isAnimated}>
            {this.renderPosts()}
          </AnimatedLoader>
          {!isLoading && this.renderPagination()}
          {isArchiveLoaded && <AppLoaded />}
        </Layout>
      </Page>
    );
  }
}

ArchivePage.propTypes = {
  tagId: PropTypes.string.isRequired,
  tagUrl: PropTypes.string.isRequired,
  tagPath: PropTypes.string.isRequired,
  fetchTagPosts: PropTypes.func.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
  allPosts: PropTypes.array,
  currentPagePosts: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  params: PropTypes.object,
  t: PropTypes.func,
  isArchiveLoading: PropTypes.bool,
  isArchiveLoaded: PropTypes.bool,
  isSite: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  layoutType: PropTypes.number.isRequired,
  excludeContent: PropTypes.bool,
  pageSize: PropTypes.number,
  entityCount: PropTypes.number,
  showPagination: PropTypes.bool,
  PostListProGallery: PropTypes.elementType.isRequired,
  canSeeMoreButton: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const year = get(ownProps, ['params', 'year']);
  const month = get(ownProps, ['params', 'month']);

  const sectionUrl = getSectionUrl(state);

  const showCreatePostAction = getIsMobile(state) && isCreatePostButtonVisible(state, ownProps.canSee);

  return {
    year,
    month,
    archiveUrl: getArchiveUrl(sectionUrl, year, month),
    allPosts: year && month ? getSortedPostsByArchiveDate(state, { year, month }) : [],
    currentPagePosts: year && month ? getPostsByArchiveDateAndPage(state, { year, month }, ownProps.page) : [],
    isArchiveLoading: getIsArchiveLoading(state),
    isArchiveLoaded: getIsArchiveLoaded(state),
    isSite: isSite(state),
    showCreatePostAction,
    excludeContent: isExcludePostContentSupported(state),
    setIsLoaded: actions.setIsLoaded,
    fetchArchive: actions.fetchArchivePromisified,
    canSeeMoreButton: getCanSeeMoreButton(state, ownProps.canSee),
  };
};

export default flowRight(
  withPermissions,
  withComponents,
  withPaginationSettings(SECTION_CATEGORY),
  withLayoutProps(),
  withAuth,
  withTranslate,
  connect(mapRuntimeToProps),
)(ArchivePage);
