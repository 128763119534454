import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHighlightWords from 'react-highlight-words';
import { getSecondaryColorNumbers } from '@wix/communities-blog-client-common';
import { shouldSearchByHashtag } from '../../../common/services/search-utils';
import { extractHashtagsFromText } from '../../../common/services/hashtag-utils';
import styles from './highlighter.scss';

const STYLE = {
  backgroundColor: `rgba(${getSecondaryColorNumbers()}, 0.3)`,
};

const getWords = (query) =>
  shouldSearchByHashtag(query) ? map(extractHashtagsFromText(query), (hashtag) => `#${hashtag}`) : query.split(' ');

const Highlighter = ({ text, query }) => (
  <ReactHighlightWords
    highlightClassName={styles.mark}
    highlightStyle={STYLE}
    textToHighlight={text}
    searchWords={getWords(query)}
  />
);

Highlighter.propTypes = {
  text: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
};

export default Highlighter;
