import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './page.scss';

const Page = ({ className, noSpacing, children, ...rest }) => {
  const containerProps = omit(rest, 'isDesktop', 'isMobile', 'section', 'layoutType', 'layoutName', 'dispatch');
  return (
    <div className={classNames(styles.container, { [styles.noSpacing]: noSpacing }, className)} {...containerProps}>
      {children}
    </div>
  );
};

Page.propTypes = {
  className: PropTypes.string,
  noSpacing: PropTypes.bool,
  children: PropTypes.node,
};

export default Page;
