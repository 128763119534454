import { LAYOUT_NAME_SIDE_BY_SIDE_MOBILE, LAYOUT_NAME_TEXT_ON_IMAGE_MOBILE } from '@wix/communities-blog-client-common';
import { isInteger } from 'lodash';
import { DEFAULT_MOBILE_VIEWPORT_WIDTH, getMobileScaleFactor } from '../../services/viewport';

const isSquareImage = (layoutName) =>
  [LAYOUT_NAME_SIDE_BY_SIDE_MOBILE, LAYOUT_NAME_TEXT_ON_IMAGE_MOBILE].includes(layoutName);

export const getImageDimensions = ({ container, component, layoutName, isSSR }) => {
  const result = { width: undefined, height: undefined };

  if (isSSR) {
    result.width = container.width || component.width || DEFAULT_MOBILE_VIEWPORT_WIDTH;
  } else {
    const scaleFactor = getMobileScaleFactor();
    result.width = Math.floor(container.width ? container.width * scaleFactor : component.width * scaleFactor);
  }

  if (isSquareImage(layoutName)) {
    result.height = result.width;
  }

  return {
    width: isInteger(result.width) ? result.width : undefined,
    height: isInteger(result.height) ? result.height : undefined,
  };
};
