import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import CreatePostButton from '../create-post-button';
import EmptyStates from '../../../common/components/empty-states';
import withTranslate from '../../../common/hoc/with-translate';

const NoPostsWriter = ({ category, t, ...props }) => (
  <EmptyStates title={t('no-posts-writer.create-posts')} content={t('no-posts-writer.readers-would-love')} {...props}>
    <CreatePostButton categorySlug={category && category.slug} />
  </EmptyStates>
);

NoPostsWriter.propTypes = {
  category: PropTypes.object,
  t: PropTypes.func,
};

export default flowRight(withTranslate)(NoPostsWriter);
