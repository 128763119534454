import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { PermissionContext } from '../context/permission-context';
import createHocName from '../services/create-hoc-name';
import createPermissionHelpers from '../services/create-permission-helpers';

const NAME = 'WithPermissions';

export default function withPermissions(WrappedComponent) {
  const WithPermissions = (props) => (
    <PermissionContext.Consumer>
      {(permissions) => (
        <WrappedComponent permissions={permissions} {...createPermissionHelpers(permissions)} {...props} />
      )}
    </PermissionContext.Consumer>
  );

  WithPermissions.displayName = createHocName(NAME, WrappedComponent);

  hoistNonReactStatics(WithPermissions, WrappedComponent);

  return WithPermissions;
}
